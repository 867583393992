// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import { ISSUER } from '../application_properties'

const { jwt: { accessTokenName, refreshTokenName } } = ISSUER

const initialUser = () => {
  const item = window.sessionStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      console.log(accessTokenName, refreshTokenName, 123)
      state.userData = action.payload
      state[accessTokenName] = action.payload[accessTokenName]
      state[refreshTokenName] = action.payload[refreshTokenName]
      sessionStorage.setItem('userData', JSON.stringify(action.payload))
      sessionStorage.setItem(accessTokenName, action.payload.accessToken)
      sessionStorage.setItem(refreshTokenName, action.payload.refreshToken)
    },
    handleLogout: state => {
      state.userData = {}
      state[accessTokenName] = null
      state[refreshTokenName] = null
      // ** Remove user, accessToken & refreshToken from sessionStorage
      sessionStorage.removeItem('userData')
      sessionStorage.removeItem(accessTokenName)
      sessionStorage.removeItem(refreshTokenName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
