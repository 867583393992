export const ISSUER = {
    baseURL: process.env.REACT_APP_BASE_URL_DEBIT,
    // baseURL: 'http://192.168.1.73:9090/api/v1',
    jwt: {
        refreshTokenName: 'refreshToken',
        accessTokenName: 'accessToken'
    },
    refreshUrl: '/refresh_token',
    tenantKey: 'Wow-Platform-TenantId',
    tenantId: 'default'
}

export const CBS = {
    baseURL: process.env.REACT_APP_BASE_URL_CBS,
    auth: {
        username: process.env.REACT_APP_CBS_ADMIN_ID,
        password: process.env.REACT_APP_CBS_ADMIN_PASS
    },

    headers: {
        'Fineract-Platform-TenantId': 'default'
    }
}

export const SCHEDULER = {
    baseURL: process.env.REACT_APP_BASE_URL_SCHEDULER
}

export const THIRD_PARTY = {
    baseURL: process.env.REACT_APP_BASE_URL_3RD_PARTY
}

export const PAGE_SIZE = 5
